import Vue from 'vue';
import Vuex from 'vuex';

import auth from './auth';
import layout from './layout';
import api from './API';
import lu from './ListUser'
import dashboards from './dashboards'

Vue.use(Vuex);

const store = new Vuex.Store({
	state: {
		api_url: 'https://crapproveapi.nitrosbase.com/',
		// api_url: 'https://democenter.nitrosbase.com/CRApproveAPIProd/',
		api_url_portal: 'https://apiportalcr.minzdrav.gov.ru/api/',
		api_analytics_url_portal: 'https://cranalyticsapi.minzdrav.gov.ru/api/PortalStaticDashboards/',
		// api_url: 'https://democenter.nitrosbase.com/CRApproveAPIProd/'
	},
	getters: {
		apiUrl: state => state.api_url,
		apiUrlPortal: state => state.api_url_portal,
	},
	modules: {
		auth,
    layout,
    api,
		lu,
		dashboards
	},
});

store.subscribe((mutation, state) => {
	localStorage.setItem('userInfo', JSON.stringify(state.auth));
});

export default store;
